/* dancing-script-400normal - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Dancing Script Regular '),
    local('Dancing Script-Regular'),
    url('./files/dancing-script-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dancing-script-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* dancing-script-700normal - latin */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Dancing Script Bold '),
    local('Dancing Script-Bold'),
    url('./files/dancing-script-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/dancing-script-latin-700.woff') format('woff'); /* Modern Browsers */
}

